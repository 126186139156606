import React from 'react';

import Layout from '../components/layout';
import withBg from '../helpers/withBg';
import SEO from '../components/seo';
import TrustpilotHorizontal from '../components/TrustpilotHorizontal/index';
import SidebarStaticPage from '../components/Sidebar/SidebarStaticPage';

const data = {
  title: 'Updates',
  seoTitle: 'Updates | Travelopod',
  seoDescription:
    'Find and book the cheapest flights to any destination at Travelopod! Save money on unique deals for flights, hotels and car rentals online or call us 24/7 for live booking assistance.'
};

class Updates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber
    };
  }

  render() {
    const { phoneNumber } = this.state;
    const description = data.seoDescription;
    const seotitle = data.seoTitle;

    return (
      <Layout
        isStaticPage
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
      >
        <SEO title={seotitle} description={description} />
        <div className="main c-content-page">
          <div className="page-content">
            <div className="flight-content">
              <div className="text-block">
                <i>Last update May 19, 2020 08:00 am</i>
                <p>
                  On March 19, 2020, the U.S. Department of State advised all
                  U.S. citizens to avoid all international travel due to the
                  ongoing <strong>Coronavirus (COVID-19)</strong> pandemic. This
                  is the{' '}
                  <strong className="blue-text">
                    highest Global Health Travel Advisory
                  </strong>{' '}
                  the U.S. government can enforce for the safety of their
                  citizens and you are highly discouraged from traveling to any
                  destination at this level.
                </p>
                <h1>Information on the Coronavirus situation</h1>
                <p>Dear customers,</p>
                <p>
                  The Coronavirus has major short term consequences for the
                  travel industry and we understand that you may have questions
                  about your upcoming travel. Below you will find answers to the
                  most frequently asked general questions and as we strive to
                  keep you informed we will update these as we get new
                  information.
                </p>
                <p>This page covers these specific topics:</p>
                <ul>
                  <li>Travel advice and entry restrictions</li>
                  <li>Stranded abroad</li>
                  <li>Check flight status</li>
                  <li>My flight is cancelled</li>
                  <li>I want to change or cancel</li>
                  <li>Contact and service</li>
                </ul>
                <p>
                  This situation is unique for us and we need your help to
                  support as many people as possible with their booking. We want
                  to assure you that we are working around the clock to help our
                  customers get to where they need to be or rearrange their
                  travel plans and we continuously assess the impact of ongoing
                  developments. Therefore we kindly ask you to take the
                  following into account.
                </p>
                <ul>
                  <li>
                    Have you already contacted us? If not please send email to{' '}
                    <a href="mailto:service@travelopod.com">
                      service@travelopod.com
                    </a>
                    . Customer service will try to contact you within 72 hours
                    before your planned departure date.
                  </li>
                  <li>
                    Please only contact us regarding bookings or flights that
                    take place within the next four weeks. Then we can serve the
                    people with an urgent departure date first.
                  </li>
                  <li>
                    Read below the most frequently asked questions and the
                    possibilities regarding your booked flight.
                  </li>
                </ul>
                <h2>Travel Advice and Entry Restrictions</h2>
                <strong className="blue-text">
                  Q: What is the current travel advice?
                </strong>
                <p>
                  <strong className="blue-text"> A:</strong> Travel advice is
                  country and region dependent and subject to change at short
                  notice. For the latest updates you should check the{' '}
                  <a href="https://www.usa.gov/coronavirus">
                    official website of Foreign Affairs
                  </a>{' '}
                  of your country.
                </p>
                <strong className="blue-text">
                  Q: What kind of measures are being taken by the national
                  authorities?
                </strong>
                <p>
                  <strong className="blue-text"> A:</strong> Many countries have
                  imposed entry restrictions. Additionally, some countries
                  perform medical checks for Corona on arrival. These checks can
                  be in the form of filling out a health certificate, a
                  temperature measurement or quarantine measures. To read the
                  latest entry requirements for the destination you are
                  traveling to, please visit the{' '}
                  <a href="https://www.iatatravelcentre.com/international-travel-document-news/1580226297.htm">
                    International Air Transport Association (IATA) website
                  </a>
                  .
                </p>
                <strong className="blue-text">
                  Q: What if my flight is still operating, but I am no longer
                  allowed to enter my destination?
                </strong>
                <p>
                  <strong className="blue-text"> A:</strong> Whether you may
                  enter a country depends on individual circumstances (e.g.
                  country of departure) and depends on where you've been prior
                  to that destination. Access to a destination is independent of
                  whether or not a flight is being operated. The responsibility
                  to find out whether a country allows you to enter lies with
                  you as a traveler. Please ensure you check this beforehand
                  using the official government website of the country for which
                  you hold a passport. In certain circumstances you may also
                  need to check the official website of the government of your
                  destination as entry restrictions may also be placed on you
                  based on your previous travel history and not just your
                  nationality. To read the latest entry requirements for the
                  destination you are traveling to, please visit the{' '}
                  <a href="https://www.iatatravelcentre.com/international-travel-document-news/1580226297.htm">
                    International Air Transport Association (IATA) website
                  </a>
                  .
                </p>
                <h2>Stranded Abroad</h2>
                <strong className="blue-text">
                  Q: Are you stranded abroad?
                </strong>
                <p>
                  <strong className="blue-text"> A:</strong> Immediate border
                  closings and government travel restrictions have left many
                  customers worldwide stranded abroad. Are you stranded abroad,
                  no possibility to travel back home and an urgent need to be
                  repatriated? We advise you to contact the Embassy or Foreign
                  Affairs of your country and register with them so that, in the
                  event repatriation flights are organized, they are aware of
                  your specific situation.
                </p>
                <h2>Check Flight Status</h2>
                <strong className="blue-text">
                  Q: How can I check the status of my flight?
                </strong>
                <p>
                  <strong className="blue-text"> A:</strong> The status of
                  flights is currently changing very rapidly, so your flight
                  status may not always be accurate. The most accurate and
                  recent information about your flight can be found on the
                  airline's website, where you can see your flight status and
                  information if your flight has been cancelled due to the
                  impact of COVID-19.
                </p>
                <strong className="blue-text">
                  Q: My flight status is still OK, can I still travel?
                </strong>
                <p>
                  <strong className="blue-text"> A:</strong> Always check the
                  latest travel advice and entry restrictions before traveling.
                  Access to a destination is independent of whether a flight is
                  being operated.
                </p>
                <h2>My Flight Is Cancelled</h2>
                <strong className="blue-text">
                  Q: Has my flight been cancelled?
                </strong>
                <p>
                  <strong className="blue-text"> A:</strong> The status of
                  flights is currently changing very rapidly. Please check the
                  airline's website to see the most accurate and recent
                  information about your flight and information if your flight
                  has been cancelled.
                </p>
                <strong className="blue-text">
                  Q: Why can it take a long time to get my refund?
                </strong>
                <p>
                  <strong className="blue-text"> A:</strong> We act as an online
                  agent between you and the airline you have booked your ticket
                  with. This means that the amount you have paid for your
                  ticket(s) has been transferred to the airline directly after
                  we issued your ticket. That is why, in case a refund is
                  applicable, the airline is the one that needs to initiate the
                  refund.
                </p>
                <p>
                  As a result of the impact of COVID-19 on the airline industry
                  these processes take longer than usual. It can take a long
                  time before you get an update on this as we are dependent on
                  each airline's delays in processing refunds. At this time we
                  cannot provide you with an exact indication of time. The
                  majority of airlines have announced that it will take 10-12
                  weeks to process all requests. We ask you not to contact us
                  about this as we will be processing all cancellations without
                  need for additional information from you. We deeply regret the
                  inconvenience and appreciate your patience and cooperation.
                </p>
                <h2>I Want To Change or Cancel</h2>
                <strong className="blue-text">
                  Q: What if I no longer want to travel? How can I CHANGE my
                  trip?
                </strong>
                <p>
                  <strong className="blue-text"> A:</strong> Changing your trip
                  may involve additional costs. Whether extra costs are
                  required, depends on the conditions of your airline. We are
                  obligated to follow the terms and conditions of the airlines.
                  <ul>
                    <li>
                      Please submit your change request by emailing us at{' '}
                      <a href="mailto:service@travelopod.com">
                        service@travelopod.com
                      </a>{' '}
                      with your reservation number. We try to answer your
                      request within 72 hours before your planned departure
                      date.
                    </li>
                  </ul>
                </p>
                <strong className="blue-text">
                  Q: My flight status is still OK, but what if I no longer want
                  to travel? How can I CANCEL my trip?
                </strong>
                <p>
                  <strong className="blue-text"> A:</strong> It is always
                  possible to cancel your trip, but whether you are entitled to
                  a refund depends on your flight ticket conditions and/or on
                  the cancellation policy of your airline. We are obligated to
                  follow the terms and conditions of the airlines.
                  <ul>
                    <li>
                      Please submit your change request by emailing us at{' '}
                      <a href="mailto:service@travelopod.com">
                        service@travelopod.com
                      </a>{' '}
                      with your reservation number. We try to answer your
                      request within 72 hours before your planned departure
                      date.
                    </li>
                  </ul>
                  <p>How does the process work?</p>
                  <ol>
                    <li>
                      If applicable, we will request the refund amount for you
                    </li>
                    <li>
                      You will receive an email from us regarding the status
                    </li>
                    <li>The airline pays the refund amount to us</li>
                    <li>We will pay the refund amount to you</li>
                  </ol>
                </p>
                <h2>Contact & Service</h2>
                <p>
                  Our customer service team is currently working day and night.
                  The focus is on bookings with an urgent departure date,
                  however, these are still extremely high numbers for us.. In
                  line with worldwide advice, all our employees work from home.
                  This adds an extra challenge.
                </p>
                <p>
                  The extreme situation means that it is not feasible to provide
                  the level of service you could normally expect from us.
                </p>
                <strong className="blue-text">
                  Q: When can I contact the customer service?
                </strong>
                <p>
                  <strong className="blue-text"> A:</strong> In order to serve
                  our customers in the best way possible, we are focusing on
                  customers with the most urgent departure dates. We give these
                  customers priority.
                </p>
                <p>
                  For change or cancellation, please email at
                  care@travelopod.com. For more efficient processing and to
                  avoid long wait, please call only if you have a trip within
                  the next 72 hrs so we can also better assist those who have to
                  travel in the next couple days. We are doing everything
                  possible to support all our customers and all our employees
                  are working extra hours.
                </p>
              </div>
            </div>
            <SidebarStaticPage phoneNumber={phoneNumber} />
          </div>
        </div>
        <TrustpilotHorizontal />
      </Layout>
    );
  }
}

export default withBg(Updates);
